import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import SVGIcon from '../components/SVGIcon'
import { useFormik, useField, Formik, Form } from 'formik'
import * as Yup from 'yup'
import ContactForm from '../components/ContactForm'

const PageContact = ({ data }) => {
  const { site } = data

  const formik = useFormik({
    initialValues: {

      name: '',
      email: '',
      phone: '',
      businessName: '',
      websiteUrl: '',
      services: '',
      message: ''
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
    }
  })

  return (
    <>
      <Layout>
        <SEO title="Contact the Majiska Team"
        description="We would love to hear about your digital vision. Let's have a chat."/>

        <section className="section--contact py-resp-32-56">

          <div className="container--padded">
            <div className="flex--margined">
              <div className="w-full lg:w-1/2 padded">
                <div className="section-contact__intro">
                  <h1 className="heading text-6xl leading-none mb-6 lg:mb-10">Let's Talk</h1>
                  <ul className="info-list flex flex-wrap -mx-6 mb-5 lg:mb-8">
                    <li className="info-group px-6">
                      <a href={`tel:${site.siteMetadata.organization.phone}`} target="_blank" className="flex items-center  text-accent">
                        <SVGIcon iconName="phone" className={`h-12 mr-3 fill-current`} /> {site.siteMetadata.organization.phone}
                      </a>
                    </li>
                    <li className="info-group px-6">
                      <a href={`mailto:${site.siteMetadata.organization.email}`} target="_blank" className="flex items-center  text-accent">
                        <SVGIcon iconName="message" className={`h-12 mr-3 fill-current`} /> {site.siteMetadata.organization.email}
                      </a>
                    </li>
                  </ul>
                  <p className="content  max-w-lg">
                    With so many tools and incentives for digital marketing,
                    there's no idea how many sales will come from only one
                    little chat. Contact us today.
                  </p>
                </div>
                <div className="section-contact__locations"></div>
              </div>
              <div className="w-full lg:w-1/2 padded mt-12 lg:mt-0 ">

                <ContactForm
                  heading={`We're ready to listen`}
                  content={
                    <p>
                      Leave you details below or call us on <a href={`tel:${site.siteMetadata.organization.phone}`} target="_blank" className="underline  text-accent"> {site.siteMetadata.organization.phone}</a> and
                      let's chat about your business, your goals, and how we can
                      help you grow. No obligation. No cost.
                    </p>
                  }
                />

              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default PageContact


export const PageQuery = graphql`{
  
    site: site {
      siteMetadata {
        organization {
          email
          phone
        }
      }
    }
  

}`