import * as React from 'react'
import { Formik, Form, useField, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import Recaptcha from 'react-google-recaptcha'

import { navigate } from 'gatsby'

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === 'undefined') {
    throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}


const FTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <li className="form__field-groups w-full">
            <label htmlFor={props.id || props.name}>{label}</label>
            <input {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </li>
    )
}
const FCheckboxes = ({ label, checkboxes, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <li className="form__field-groups w-full">
            <label>{label}</label>
            <ul className="checkbox-groups">
                {checkboxes.map(({ label, value }, i) => {
                    return (
                        <li key={i} className="checkbox__field-group">

                            <Field id={`cb-${i}`} type="checkbox" name="services" value={value} />
                            <label htmlFor={`cb-${i}`}>{label}</label>
                        </li>
                    )
                })}
            </ul>
            <ErrorMessage name="services" />
        </li>
    )
}
const FMessage = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <li className="form__field-groups w-full">
            <label>{label}</label>
            <textarea {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </li>
    )
}
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}
export default function ContactForm({ heading, content }) {

    const recaptchaRef = React.createRef()

    return (
        <Formik

            initialValues={{ name: '', email: '', businessName: '', websiteUrl: '', services: [], message: '' }}
            validationSchema={Yup.object({
                name: Yup.string().max(15, 'Must be 15 chars or less').required('Required'),
                email: Yup.string().email('Invalid email address').required('Required'),
                phone: Yup.string().required('Required'),
                businessName: Yup.string(),
                websiteUrl: Yup.string().matches(
                    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter correct url!'
                ),
                services: Yup.array().min(1, 'choose one').required('REQUIRED'),
                message: Yup.string()
            })}
            onSubmit={(values, { setSubmitting }) => {
                console.log('submit', values)
                const recaptchaValue = recaptchaRef.current.getValue()

                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({
                        "form-name": "contactform",
                        'g-recaptcha-response': recaptchaValue,
                        ...values
                    })
                }).then(() => navigate("/success/")).catch(error => alert(error))
            }}
        >
            <Form
                name="contactform"
                data-netlify="true"
                data-netlify-recaptcha="true"
                className={`section-contact__form bg-theme-dark py-resp-6-14 px-resp-6-14 -mx-4 lg:mx-0`}
            >
                {/* <input type="hidden" name="bot-field" /> */}
                <div className="form__intro py-4 lg:pt-6 lg:pb-8">
                    <h3 className="heading text-2xl leading-none mb-4">{heading}</h3>
                    {content}
                </div>
                <input type="hidden" name="form-name" value="contactform" />
                <ul className="form-fields flex flex-wrap">
                    <FTextInput
                        label="Name *"
                        name="name"
                        type="text"
                    />
                    <FTextInput
                        label="Email *"
                        name="email"
                        type="email"
                    />
                    <FTextInput
                        label="Phone *"
                        name="phone"
                        type="text"
                    />
                    <FTextInput
                        label="Business Name"
                        name="businessName"
                        type="text"
                    />
                    <FTextInput
                        label="Website URL"
                        name="websiteUrl"
                        type="text"
                    />
                    <FCheckboxes
                        label="How can we help?"
                        name="services"
                        checkboxes={[
                            { 'label': 'SEO', 'value': 'seo' },
                            { 'label': 'Google Adwords', 'value': 'adwords' },
                            { 'label': 'Social Media Marketing', 'value': 'smm' },
                            { 'label': 'Website Design', 'value': 'webdesign' },
                            { 'label': 'Consulting', 'value': 'consulting' }
                        ]}
                    />
                    {/* <div data-netlify-recaptcha="true"></div> */}


                    <FMessage
                        label="Message"
                        name="message"

                    />
                    <div className="mt-3 lg:mt-6"> <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} /></div>
                    <button type="submit" className="btn-submit mt-8 btn btn--primary btn--large btn--full" value="submit" >Submit</button>

                </ul>

            </Form>
        </Formik>
    )
}